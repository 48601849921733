<script setup>
import Checkmark from '@/Components/Input/Checkmark.vue';
import { createInput } from '@formkit/vue';
import { Link, useForm } from '@inertiajs/vue3';
import { watchDebounced } from '@vueuse/core';
import { inject, ref, watch } from 'vue';

import theme from '@/formkitTheme';
import GuestLayout from '@/Layouts/GuestLayout.vue';
let route = inject('route');

const props = defineProps({
    typeOptions: Object,
});
const values = ref({});
const checkmark = createInput(Checkmark);

const kvkFrom = useForm({
    kvk_number: '',
});

const registrationForm = useForm({
    kvk_number: '',
    type: Object.keys(props.typeOptions)[0],
    email: '',
    password: '',
    password_confirmation: '',
    terms: false,
});

const showCompany = ref(false);
const companyData = ref({});
const companyError = ref({});

watchDebounced(
    () => registrationForm.kvk_number,
    async () => {
        companyError.value = {};
        showCompany.value = false;
        window.axios
            .get(
                route('api.v0.kvk-controller.show', {
                    kvk_number: registrationForm.kvk_number,
                })
            )
            .then((t) => {
                companyData.value = t.data;
                showCompany.value = true;
            })
            .catch(function (error) {
                if (error?.response?.status === 422) {
                    registrationForm.errors.kvk_number = error.response.data.errors.kvk_number[0];
                } else {
                    console.error(error.response.data);
                    console.error(error.response.status);
                    console.error(error.response.headers);
                }
            });
    },
    { debounce: 1000, maxWait: 3000 }
);

watch(kvkFrom, () => {
    showCompany.value = false;
});

const submit = () => {
    registrationForm
        .transform((data) => ({
            ...data,
            terms: registrationForm.terms === true ? 'on' : '',
        }))
        .post(route('register'), {
            onFinish: () => registrationForm.reset('password'),
        });
};
</script>

<template>
    <GuestLayout title="Registreer">
        <div class="container w-full flex-center">
            <div class="w-full max-w-75 my-15">
                <FormKit
                    type="form"
                    submit-label="Registreer"
                    @submit="submit()"
                    :input-errors="registrationForm.errors"
                    :submit-attrs="{
                        inputClass: theme.button.input,
                        wrapperClass: theme.button.wrapper,
                    }"
                >
                    <FormKit
                        type="text"
                        name="kvk_number"
                        label="Kvk nummer"
                        validation="required"
                        v-model="registrationForm.kvk_number"
                    />

                    <div v-if="showCompany" class="px-3 py-4 mb-12 -mt-3 text-xs text-green-700 bg-green-400 rounded">
                        <div class="mb-3 font-bold">
                            {{ companyData.name }}
                        </div>
                        <div class="">
                            {{ companyData.street_name }}
                            {{ companyData.street_number }}
                            {{ companyData.street_number_addition }}
                        </div>
                        <div class="">
                            {{ companyData.postcode }}
                            {{ companyData.city }}
                        </div>
                    </div>

                    <FormKit
                        type="select"
                        label="Aanmelden als"
                        name="small_country"
                        v-model="registrationForm.type"
                        :options="typeOptions"
                    />

                    <FormKit
                        type="email"
                        name="email"
                        label="E-mailadres"
                        validation="required|email"
                        v-model="registrationForm.email"
                    />

                    <FormKit
                        type="password"
                        name="password"
                        label="Wachtwoord"
                        validation="required"
                        v-model="registrationForm.password"
                    />

                    <FormKit
                        type="password"
                        name="password_confirm"
                        label="Wachtwoord herhaling"
                        validation="required|confirm"
                        validation-label="Wachtwoord herhaling"
                        v-model="registrationForm.password_confirmation"
                    />

                    <Checkmark v-model:value="registrationForm.terms" :error="registrationForm.errors.terms">
                        <div class="leading-tight">
                            Ik ga akkoord met de
                            <Link class="font-bold underline" :href="route('terms.show')">Algemene Voorwaarden </Link>
                            en
                            <Link class="font-bold underline" :href="route('policy.show')">Privacy Verklaring </Link>
                        </div>
                    </Checkmark>
                </FormKit>

                <div class="w-full mt-8 text-sm text-center">
                    Heb je al een account?
                    <Link class="font-bold" :href="route('login')">Inloggen</Link>
                </div>
            </div>
        </div>
    </GuestLayout>
</template>
